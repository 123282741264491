import React from 'react';
import Tile from "./service-tile";
import "./Services.css";
import ContactTile from './contact-tile';


export default () => {


    let firstRow = [
        {
            imageSrc: "pa_400x300_3.jpg",

            content: {
                title: "People and HR Analytics",
                description: "Leveraging on science to improve people and enterprise-wide performance",
                readMore: "/people-and-hr-analytics"
            }
        },
        {
            imageSrc: "online_assessments_400x300_8.jpg",
            content: {
                title: "Online Assessments",
                description: "Testing that uses a number of different techniques to arrive at some hypothesis about a person and" +
                    " their behaviour, personality and capabilities",
                readMore: "/online-assessments"
            }
        },
    ];

    let secondRow = [
        {
            imageSrc: "talent_management_400x300.jpg",
            content: {
                title: "Talent Management",
                description: "Using predictive analytics to uncover new insights , business models, processes and systems that improve business performance and maximise growth.",
                readMore: "/talent-management",
            },
            reverse: true
        },
        {
            imageSrc: "labour_law_300x400.jpg",
            content: {
                title: "Labour Law Services",
                description: "Mediation & Advisory services on Labour Law & Employment Law",
                readMore: "/labour-law-services"
            },
            reverse: true
        }
    ];

    const lastRowService =
        {
            imageSrc: "mc_400x300_9.jpg",
            content: {
                title: "Management Consulting",
                description: "Use predictive analytics to uncover new insights; business models, processes and system and mitigate risk",
                readMore: "/management-consulting",
            }
        };


    return <div className={"services"}>
        <div className="section-header">
            <h1>Services</h1>
        </div>
        <div className="service-tiles">
            {firstRow.map((s, i) => <Tile {...s} key={i}/>)}
        </div>
        <div className="service-tiles">
            {secondRow.map((s, i) => <Tile {...s} key={i}/>)}
        </div>

        <div className="service-tiles">
            <Tile {...lastRowService} key={lastRowService.title}/>
            <ContactTile/>
        </div>
    </div>;

}
