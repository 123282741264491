import React from "react";
import "./online-assessments.css";
import Hero from "../../components/hero";
import Enquire from "../../components/enquire";
import serviceMappings from "../../service-category-mappings";

export default () => (
    <section>
        <Hero bg={"oa-banner.jpg"}/>
        <div className={'online-assessments-main'}>
            <div className={'section-header'}>
                <h1>Online Assessments</h1>
            </div>

            <p className={'service-intro'}>
                Psychological assessment, sometimes referred to as psychological testing is a process of testing that
                uses a
                number of different techniques to arrive at some hypotheses about a person and their behaviour, their
                personality and capabilities. It is also a process that helps identifies not just weaknesses of a
                person,
                but also their strengths and facilitate for better decision making in different settings, from hiring
                and
                development decisions to diagnosis and management of symptoms.
            </p>
            <div>
                <h1 className={'service-section-heading'}>
                    Our philosophy
                </h1>
                <ul className={'service-points'}>
                    <li>
                        Psychological assessments and psychometric assessments should never be performed in a vacuum but
                        rather as part of a thorough assessment of an individual.
                    </li>
                    <li>
                        Psychological testing measures an individual’s performance at a specific point in time.
                    </li>
                    <li>
                        The purpose of assessing should be clear and a battery put together on the basis of the purpose.
                    </li>
                </ul>
            </div>
            <>
                <div className={'section-header'}>
                    <h1>Service offering</h1>
                </div>

                <p className={'service-intro'}>
                    Our assessment services are divided into those used in the occupational setting (referred to as
                    psychometric assessments) and those used in the clinical setting mostly with individuals.
                </p>


                <h1 className={'service-section-heading'}>
                    Psychometric assessment (Online Assessments):
                </h1>

                <p className={'service-intro'}>
                    Psychometric tests are designed to measure candidates' suitability for a role based on the required
                    personality characteristics and aptitude (or cognitive abilities). They identify the extent to which
                    candidates' personality and cognitive abilities match those required to perform a specific role.
                    Information gathered/ collected through this process allows employers to identify those hidden
                    aspects
                    of candidates that are often difficult to extract from a face-to-face interviews.

                    <br/>
                    <br/>
                    These are conducted on line and can be either supervised or non-supervised, meaning a candidate can
                    do
                    them at the comfort of their space. These are provided to help in hiring or development spaces.
                    These
                    include instruments/ tests/ measures of:
                </p>

                <ul className={'service-points'}>
                    <li>Personality</li>
                    <li>Ability tests</li>
                    <li>Competency based assessments</li>
                    <li>Emotional Intelligence</li>
                    <li>Integrity assessments</li>
                    <li>Levels of work assessment</li>
                    <li>Career guidance</li>
                </ul>
            </>
            <>
                <div className={'section-header'}>
                    <h1>Clinical Assessments</h1>
                </div>
                <p className={'service-intro'}>
                    Our assessment services are divided into those used in the occupational setting (referred to as
                    psychometric assessments) and those used in the clinical setting mostly with individuals.
                    <br/>
                    <br/>
                    These include instruments / tests/ measures of:
                </p>

                <ul className={'service-points'}>
                    <li>Personality</li>
                    <li>Intelligence quotient</li>
                    <li>Emotional intelligence</li>
                    <li>Behavioural assessments</li>
                    <li>Neuropsychological assessments</li>
                </ul>
            </>
        </div>
        <Enquire services={["Online Assessments", ...serviceMappings["Online Assessments"]]}/>
    </section>
)
