import Services from "../../services";
import NextEvent from "../../event";
import Hero from "../../components/hero";
import React from "react";

const events = [
    {
        title: "Webinar on: Virtual Performance Management lockdown and beyond",
        description:
            (<>
                <strong>Time</strong>: 10AM - 11:30 AM
                <br/>
                <strong>Cost</strong>: free
            </>),
        link: "/contact-us",
        month: "AUGUST",
        day: "06"
    },
    {
        title: "Webinar on: Retrenchment planning and implementation during and post lockdown",
        description:
            (<>
                <strong>Cost</strong>: free
            </>),
        link: "/contact-us",
        month: "SEPTEMBER",
        day: "29"
    },
    {
        title: "Webinar on:  Implementation of new and amended labour laws",
        description:
            (<>
                Labour Relations Act amendments
                <br/>
                New Minimum Wage Act
                <br/>
                Employment Act (BCEA)
                <br/>
                Employment Tax Incentive Act
                <br/>
                <br/>
            </>),
        link: "/contact-us",
        month: "OCTOBER",
        day: "29"
    },
    {
        title: "Business and cultural transformation",
        description: "",
        link: "/contact-us",
        month: "November",
        day: "30"
    }
]

export default () => (
    <>
        <Hero bg={"home-banner.jpg"}
              text={<h1>Where people and science <br/>
                  <span style={{color: '#2AABE3'}}>improve business performance </span> &
                  <br/>
                  maximise growth
              </h1>}/>
        <section>
            <Services/>
        </section>

        <section>
            <div className={'next-event'}>
                <div className="section-header">
                    <h1>Upcoming Events</h1>
                </div>
                {events.map(e => <NextEvent {...e} key={e.title}/>)}
            </div>
        </section>
    </>
);
