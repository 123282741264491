import React from "react";
import {Link} from "react-router-dom";


export default ({imageSrc, content, reverse}) => {

    const image = <div className={"tile-image-wrapper"}>
        <img src={imageSrc} alt={content.title}/>
    </div>;

    const text = <div className="tile-text-content">
        <h1>{content.title}</h1>
        <p>
            {content.description}
        </p>

        <Link className="button" to={content.readMore}>Read more</Link>

    </div>;

    return <div className={`tile ${reverse ? 'reverse' : ""}`}>
        {image}
        {text}
    </div>

}
;

