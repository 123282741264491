import React from "react";

export default ({imageSrc, header, description}) => (
    <div className={"tm-service"}>
        <div className={"tm-service-image-wrapper"}>
            <img src={imageSrc} alt={header}/>
        </div>
        <div className={"tm-service-commentary"}>
            <h1>{header}</h1>
            <p>{<>{description}</>}</p>
        </div>
    </div>
);
