import React from "react";
import Hero from "../../components/hero";
import Enquire from "../../components/enquire";
import "./talent-management.css"
import TMService from "./tm-service";
import serviceMappings from "../../service-category-mappings";

export default () => (
    <section>
        <Hero bg={"hrm-banner.jpg"}/>
        <div className={'section-header'}>
            <h1>
                Talent Management
            </h1>
        </div>
        <div className={'side-padded'}>
            <p className={'service-intro'}>
                We use talent analytics to uncover new insights, business models, processes and systems; to improve
                people and business competencies, sustain optimum performance and maximise growth.
            </p>
        </div>

        <TMService header={"Talent Acquisition"}
                   description={'Sourcing best talent by drafting job advertisement, response handling from ' +
                   'shortlisting, job match assessments, interview to placement, placements reports and brief overview -' +
                   ' orientation to the potential company including On-boarding & engagement.'}
                   imageSrc={"talent-acquisition.svg"}
        />

        {/*<TMService header={"On-boarding & engagement"}*/}
        {/*           description={''}*/}
        {/*           imageSrc={"onboarding.svg"}*/}
        {/*/>*/}
        <TMService header={"Performance Management"}
                   description={'Develop performance management framework and policies including preparation of ' +
                   'communication, training of all stakeholder for implementation.'}
                   imageSrc={"perf-management.svg"}
        />

        <TMService header={"Development management"}
                   description={'Leadership and management development including supervisory levels from assessing ' +
                   'areas of development, developing customised training and development programmes including ' +
                   'mentoring and executive coaching.'}
                   imageSrc={"development.svg"}
        />

        <Enquire services={["Talent Management", ...serviceMappings["Talent Management"]]}/>
    </section>
);
