import {Field} from "formik";
import React from "react";
import "./contact.css";

export default (props) => {
    return (
        <Field name={props.name}>
            {({field, form}) => (
                <label className="container">
                    <input
                        type="checkbox"
                        {...props}
                        checked={field.value.includes(props.value)}
                        onChange={() => {
                            if (field.value.includes(props.value)) {
                                const nextValue = field.value.filter(
                                    value => value !== props.value
                                );
                                form.setFieldValue(props.name, nextValue);
                            } else {
                                const nextValue = field.value.concat(props.value);
                                form.setFieldValue(props.name, nextValue);
                            }
                        }}
                    />
                    {props.value}
                    <span className="checkmark"/>
                </label>
            )}
        </Field>
    );
}
