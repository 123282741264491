import React from "react";
import "./Footer.css";

export default () => (
    <div className={'footer'}>
        <div className={'footer-logos'}>
            <div className={'footer-logo'}>
                <a href={'https://www.facebook.com/3Gems-Consulting-109542150845622'}
                   target={'_blank'} rel='noopener noreferrer'>
                    <img src={'fb-logo.svg'} alt={'facebook logo'}/>
                </a>
            </div>
            {/*<div className={'footer-logo'}>*/}
            {/*    <img src={'linkedin-logo.svg'} alt={'linkedin logo'}/>*/}
            {/*</div>*/}
        </div>
        <div className={'footer-content'}>
            <span>&copy; {new Date().getFullYear()} Nip Agency</span>
        </div>
    </div>
);
