import React, {useRef, useState} from "react";
import "./contact.css"
import "../../index.css"
import {Field, Formik} from "formik";
import serviceCategoryMappings from "../../service-category-mappings.json";
import querystring from "querystring";
import ServiceCategory from "./Service-Category";
import ServiceOption from "./ServiceOption";
import ContactInfo from "./contact-info";
import ReCAPTCHA from "react-google-recaptcha";
import "whatwg-fetch";
import {Link,} from "react-router-dom";


export default ({location, history}) => {

    const recaptchaRef = useRef(null);
    const [isBusy, setIsBusy] = useState(false);
    const [currentFormValues, setCurrentFormValues] = useState({});

    const search = location.search.replace("?", "");
    const query = querystring.parse(search);
    query.services = query.services || [];

    async function submit(captcha) {
        await fetch("https://dfllts7bve.execute-api.us-east-1.amazonaws.com/dev/submissions", {
            method: "POST",
            body: JSON.stringify({...currentFormValues, captcha}),
            redirect: "manual"
        });
        setIsBusy(false);
        history.push("/contact-us?submitted=true")
    }

    return <section>

        <div className="contact-hero" style={{backgroundImage: 'url("contact-banner.jpg")'}}>
            <ContactInfo/>
        </div>

        {query.submitted ? <div className={"submit-success"}>
                <div>
                    <h1>Thank you! Your submission was received and we will be in touch
                        soon. </h1>
                </div>
                <Link to={"/"}>Show me all available services</Link>
            </div> :

            <Formik
                initialValues={{
                    firstName: "",
                    email: "",
                    phoneNumber: "",
                    surname: "",
                    services: query.services,
                    additionalInformation: ""
                }}
                onSubmit={(values) => {
                    setIsBusy(true);
                    recaptchaRef.current.execute();
                    setCurrentFormValues(values);
                }}
            >
                {({errors, touched, handleSubmit}) => (
                    <form name="contact" className={"form"} onSubmit={handleSubmit}>
                        <ReCAPTCHA
                            onChange={submit}
                            ref={recaptchaRef}
                            size={"invisible"}
                            sitekey="6LfZZN4UAAAAAA9lwALfh8wb9w32L-shIgiWhBKF"
                            onErrored={() => setIsBusy(false)}
                        />
                        <div className={"form-input-group"}>
                            <div className={"field-group-row"}>
                                <div className={"field-group"}>
                                    <label htmlFor="firstName">First Name</label>
                                    <Field name="firstName" type={"text"} required/>
                                </div>
                                <div className={"field-group"}>
                                    <label htmlFor="surname">Surname</label>
                                    <Field name="surname" type={"text"} required/>
                                </div>
                            </div>
                            <div className={"field-group-row"}>
                                <div className={"field-group"}>
                                    <label htmlFor="email">Email Address</label>
                                    <Field name="email" type={"email"} required/>
                                </div>
                                <div className={"field-group"}>
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <Field name="phoneNumber" type={"text"} required/>
                                </div>
                            </div>
                        </div>
                        <div className={"field-group-row"}>
                            <div className={"field-group"}>
                                <label htmlFor="additionalInformation">Additional Information</label>
                                <Field name="additionalInformation" component={"textarea"} rows={6}/>
                            </div>
                        </div>
                        <div className={"service-selection"}>
                            <h1>Looking for specific information?</h1>
                            <h2>Choose from our list of services from below </h2>
                            {Object.keys(serviceCategoryMappings).map((c, i) => (
                                <div key={i}>
                                    <div className={"service-category-option"}>
                                        <ServiceCategory name={`services`} value={c}
                                        />
                                    </div>
                                    <div className={"service-option"}>
                                        {serviceCategoryMappings[c].map((s, j) => <ServiceOption name={`services`}
                                                                                                 value={s}
                                                                                                 key={j * 10}/>)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button style={{display: "flex", justifyContent: "space-evenly", transition: "0.3s"}}
                                type="submit"
                                className={"submit"} disabled={isBusy}>
                            submit{isBusy && <img style={{width: 40}} src={"loading.svg"} alt={"loading"}/>}
                        </button>
                    </form>
                )}
            </Formik>}
    </section>;
};
