import React from "react";
import Menu from "./menu";
import "./Nav.css"
import {Link} from "react-router-dom";

export default () => {

    return <nav role={'navigation'} className='navbar'>
        <div className={'logo-wrapper'}>
            <Link to={"/"}>
                <img src={'3Gems-Consulting-500-x-144px.JPG'} alt={'3Gems logo'}/>
            </Link>
        </div>
        <Menu/>
    </nav>
};
