import React from "react";
import "./Event.css";
import {Link} from "react-router-dom";

export default ({title, description, link, month, day}) => (
    <div className={'event'}>
        <div className={'event-image'}>
            <div className={'event-month'}>
                {month}
            </div>
            <div className={'event-day'}>
                {day}
            </div>
        </div>
        <div className={'event-content'}>
            <h1>{title}</h1>
            <p>
                {description}
            </p>
            <button>
                <Link className="button" to={link}>Enquire</Link>
            </button>
        </div>
    </div>
);
