import React from "react";

export default () => (
    <div className={"contact-info"}>
        <h1>Reach out to us</h1>
        <div className={"contact-info-row"}>
            <div className={"img-wrapper"}>
                <img src={"address.svg"} alt={"address"}/>
            </div>
            The Ridge, 1 Ateljee Street, Randpark Ridge
        </div>
        <div className={"contact-info-row"}>
            <div className={"img-wrapper"}>
                <img src={"envelope.svg"} alt={"postal address"}/>
            </div>
            P O Box 90529, Bertsham, 2013
        </div>
        <div className={"contact-info-row"}>
            <div className={"img-wrapper"}>
                <img src={"phone.svg"} alt={"phone"}/>
            </div>
            065 916 8255
        </div>
        <div className={"contact-info-row"}>
            <div className={"img-wrapper"}>
                <div style={{fontSize:28}}>@</div>
            </div>
            admin@3gemsconsulting.com
        </div>
    </div>
);
