import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";


export default () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showServicesMenu, setShowServicesMenu] = useState(false);
    const wrapperRef = useRef(null);
    const hamRef = useRef(null);

    function useOutsideAlerter(ref) {


        function handleClickOutside(event) {
            if (ref.current &&
                !hamRef.current.contains(event.target) &&
                !ref.current.contains(event.target)
            ) {

                setShowMobileMenu(false);
                setShowServicesMenu(false);
            }
        }

        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }

    useOutsideAlerter(wrapperRef);

    function hide() {
        setShowMobileMenu(false);
        setShowServicesMenu(false);
    }

    const ServiceLink = ({title, to}) => (
        <Link to={to} onClick={hide} className={"service-link"}>{title}</Link>
    );

    return <>
        <ul ref={wrapperRef} className={'menu-links ' + (!showMobileMenu ? 'hidden' : "")}>
            <li><Link to="/" onClick={hide}>Home</Link></li>
            <li className={"has-sub-menu"}
                onMouseLeave={() => setShowServicesMenu(false)}
                onMouseEnter={() => setShowServicesMenu(true)}
            >
                <a className={"services-link"}>Services</a>
                <ul className={"dropdown " + ((!showMobileMenu && !showServicesMenu) ? 'hidden' : "")}>
                    <li><ServiceLink to="/people-and-hr-analytics" title={"People and HR Analytics"} onClick={hide}/></li>
                    <li><ServiceLink to="/online-assessments" title={"Online Assessments"} onClick={hide}/></li>
                    <li><ServiceLink to="/management-consulting" title={"Management Consulting"} onClick={hide}/></li>
                    <li><ServiceLink to="/talent-management" title={"Talent Management"}
                                     onClick={hide}/></li>
                    <li><ServiceLink to="/labour-law-services" title={"Labour Law Services"} onClick={hide}/></li>
                </ul>
            </li>
            {/*<li><a href="/#" onClick={hide}>EVENTS</a></li>*/}
            <li><Link to="/contact-us" onClick={hide}>CONTACT</Link></li>
        </ul>
        <div ref={hamRef} className={"hamburger"} onClick={handleHamburgerClick}>
            <div className={"bar1 " + (showMobileMenu ? "open" : "")}/>
            <div className={"bar2 " + (showMobileMenu ? "open" : "")}/>
            <div className={"bar3 " + (showMobileMenu ? "open" : "")}/>
        </div>
    </>;

    function handleHamburgerClick(e) {
        setShowMobileMenu(!showMobileMenu);
    }
};
