import React from "react";
import Service from "../../components/sub-service"
import "./labour-law-services.css"
import Hero from "../../components/hero";
import Enquire from "../../components/enquire";
import serviceMappings from "../../service-category-mappings";

export default () => (
    <section>
        <Hero bg={"labour-law-banner.jpg"}/>
        <div className={'section-header'}>
            <h1>
                Labour law services
            </h1>
        </div>

        <div className={'side-padded'}>
            <p className={'service-intro'}>
                We provide overall facilitation in employer and employee relationships to ensure sound labour relations
                including organised labour as employee representatives and employees as they are employees before they
                are union members. We provide services in the following areas
            </p>
        </div>
        <div className={'sub-services'}>
            <Service
                imageSrc={'chairing.svg'}
                heading={'Chairing of disciplinary hearings'}
                description={'Facilitate the disciplinary hearing process and train the trainer courses to empower line ' +
                'management - providing management toolkit '}>
            </Service>
            <Service
                imageSrc={'disputes.svg'}
                heading={'Discipline and Grievances'}
                description={'Managing the conduct and behavior of employees.'}>
            </Service>
            <Service
                imageSrc={'retrench.svg'}
                heading={'Retrenchments & Restructuring'}
                description={'Provide advisory services on retrenchment to ensure total compliance and mitigating' +
                ' litigations to reduce or prevent unnecessary legal costs'}>
            </Service>
            <Service
                imageSrc={'labour-court.svg'}
                heading={'Labour court preparation & representation'}
                description={'Labour court and CCMA preparation & representation through advisory services and ' +
                'preparing cases'}>
            </Service>
            <Service
                imageSrc={'industrial-relations.svg'}
                heading={'Industrial Relations'}
                description={'Managing the relationship between employer and organised labour'}>
            </Service>
            <Service
                imageSrc={'contracts.svg'}
                heading={'Employee Relations'}
                description={'Managing the relationship between employer and its employees'}>
            </Service>
            <Service
                imageSrc={'wages.svg'}
                heading={'Wage negotiations'}
                description={' '}>
            </Service>
            <Service
                imageSrc={'chairing.svg'}
                heading={'Dispute handling'}
                description={' '}>
            </Service>
            <Service
                imageSrc={'advisory.svg'}
                heading={'Advisory services on labour law & employment law'}
                description={''}>
            </Service>
        </div>
        <Enquire services={["Labour Law Services", ...serviceMappings["Labour Law Services"]]}/>
    </section>
);
