import React from 'react';
import './App.css';
import Nav from './nav';
import {BrowserRouter as Router, Route, Switch, useLocation} from "react-router-dom";
import Home from "./pages/home";
import OnlineAssessments from "./pages/online-assessments";
import ManagementConsulting from "./pages/management-consulting";
import Contact from "./pages/contact";
import TalentManagement from "./pages/talent-management";
import LLS from "./pages/labour-law-services";
import PeopleAnalytics from "./pages/people-analytics";
import Footer from "./footer";
import ScrollToTop from "./components/scroll-to-top";
import {CSSTransition, TransitionGroup} from "react-transition-group";

function App() {


    return (
        <Router>
            <ScrollToTop/>
            <div className="App">
                <Nav/>
                <div className={'main-content'}>
                    <Animated/>
                    <section>
                        <Footer/>
                    </section>
                </div>
            </div>
        </Router>
    );


}

function Animated() {
    let location = useLocation();
    return <TransitionGroup>
        <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={300}
        >
            <Switch location={location}>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path="/online-assessments">
                    <OnlineAssessments/>
                </Route>
                <Route path="/management-consulting">
                    <ManagementConsulting/>
                </Route>
                <Route path="/talent-management">
                    <TalentManagement/>
                </Route>
                <Route path="/labour-law-services">
                    <LLS/>
                </Route>
                <Route path="/people-and-hr-analytics">
                    <PeopleAnalytics/>
                </Route>
                <Route path="/contact-us" component={Contact}/>
            </Switch>
        </CSSTransition>
    </TransitionGroup>

}

export default App;
