import React from "react";

export default ({header, imageSrc, serviceItems, reverse}) => (
    <div className={`pa-service ${reverse ? 'reverse' : ""}`}>
        <divc className={'pa-service-image-wrapper'}>
            <img src={imageSrc} alt={header}/>
        </divc>
        <div className={'pa-service-commentary'}>
            <h1>
                {header}
            </h1>
            <ul>
                {serviceItems.map(i => <li key={i}>{i}</li>)}
            </ul>
        </div>
    </div>
);
