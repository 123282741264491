import React from "react";


export default ({imageSrc, heading, description}) => (
    <>
        <div className={'mc-service'}>
            <img src={imageSrc} alt={heading}/>
            <h1 className={'service-header'} >
                {heading}
            </h1>
            <div className={'service-description'}>
                {description}
            </div>
        </div>
    </>
);
