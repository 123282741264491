import React from "react";
import Service from "../../components/sub-service";
import Hero from "../../components/hero";
import "./management-consulting.css";
import Enquire from "../../components/enquire";
import serviceMappings from "../../service-category-mappings";

export default () => (
    <section>
        <Hero bg={"mc-banner.jpg"}/>
        <div className={'section-header'}>
            <h1>
                Management Consulting
            </h1>
        </div>
        <div className={'side-padded'}>
            <p className={'service-intro'}>
                We provide tools to address business challenges, create value, maximise growth and improve business
                performance; by using own business skills to provide objective advice and expertise.

                <br/>
                <br/>
                We provide tools and systems to develop core and specialist skills that may be lacking ranging from
                business planning, strategy development and various capital project management - business re-engineering
                and any due diligence in mergers and acquisitions.
            </p>
        </div>
        <div className={'sub-services'}>
            <Service
                imageSrc={'mc-strategy-development.svg'}
                heading={'STRATEGY DEVELOPMENT & FACILITATION'}
                description={'Analysis of business strategy, structure, vision , values and implementation- operational' +
                ' plans, in the absence of strategy we assist in the preparation  and development of business strategy, ' +
                'fit for purpose structure and operational plan by following the entire process that is used to ' +
                'identify, nurture and acquire new clients and business opportunities to drive growth and profitability.'
                }>
            </Service>

            <Service
                imageSrc={'mc-project-management.svg'}
                heading={'PROJECT MANAGEMENT'}
                description={'Provide project planning, administration, facilitation, managing involved resources in ' +
                'capital intensive projects and ensure delivery within the cost and time constraints.'}>
            </Service>

            <Service
                imageSrc={'mc-compliance.svg'}
                heading={'Statutory and Corporate reporting'}
                description={'Provide resources to enable companies to submit quality reports that meet the set' +
                ' standards- and can stand any form of scrutiny. Assist in compilation and submission of the following' +
                ' but not limited to Employment Equity, Workplace Skills and Boards report.'}>
            </Service>

            <Service
                imageSrc={'mc-mergers.svg'}
                heading={'MERGERS & ACQUISITIONS'}
                description={'Integrating the administration, operations, technology and/or products of two firms.'}>
            </Service>

            <Service
                imageSrc={'mc-board-reporting.svg'}
                heading={'EMPLOYEE SATISFACTION SURVEY'}
                description={'Provide insights into the employee happiness and level of engagement at work and the' +
                ' health of the organisation. Integrate science in designing measures to address areas of concern.'}>
            </Service>
        </div>
        <h1 className={'service-section-heading'}>
            Restructuring & Re-Engineering
        </h1>

        <p className={'service-intro'}>
            We provide restructuring and re-organisation services including assessing the benefits of the new
            structure; the costs and risks of the change process itself in the following areas:
        </p>
        <ul className={'service-points'}>

            <li>
                <strong>Legal</strong> - where parts of structure is changed to align with strategy direction- changing
                the legal
                structure
                of a firm such as ownership structure.
            </li>

            <li>
                <strong>Financial and Cost Restructuring</strong> - where the business restructure certain elements of
                the business for
                sustainability.
            </li>

            <li>
                <strong>Turnaround strategy</strong> - to break even or make profit or grow the business.
            </li>

            <li>
                <strong>Repositioning the business</strong> - to remain relevant and competitive in the market.
                Including divestment
                and Spin-off.
            </li>
        </ul>

        <Enquire services={["Management Consulting", ...serviceMappings["Management Consulting"]]}/>

    </section>
);
