import React from "react";
import Hero from "../../components/hero";
import "./people-analytics.css";
import Enquire from "../../components/enquire"
import HrAnalyticsService from "./hr-analytics-service"

export default () => (
    <section>
        <Hero bg={"pa-banner.jpg"}/>
        <div className={'section-header'}>
            <h1>
                People Analytics
            </h1>
        </div>
        <div className={'side-padded'}>
            <p className={'service-intro'}>
                We provide a scientific approach for decision making; improving business performance and people impact
                in the business.
                <br/>
                We use predictive analytics to uncover new insights, business models, processes, system and mitigate
                risk.
                <br/>
                <br/>
                People Analytics also provides input and direction to our HR analytics solutions on how to:
                <ul className={'service-points'}>
                    <li>
                        Address people related challenges,
                    </li>
                    <li>
                        Develop new methodologies, systems and programmes to leverage on the business’ core competencies
                        and
                    </li>
                    <li>
                        Develop new methodologies in the design of HR models and standards.
                    </li>
                </ul>
            </p>
        </div>

        <div className={'side-padded'}>
            <div className={'people-analytics-pointers'}>
                <div className={'people-analytics-pointer'}>
                    <h1 className={'people-analytics-pointer-header-desktop'}>Performance</h1>
                    <div className={'pa-pointer-image-wrapper'}>
                        <img alt='performance' width={180} src={'pa-performance.svg'}/>
                    </div>
                    <div className={'pa-bar'}/>
                    <h1 className={'people-analytics-pointer-header-mobile'}>Performance</h1>
                    <div className={'pa-bar'}/>
                </div>
                <div className={'people-analytics-pointer'}>
                    <h1 className={'people-analytics-pointer-header-desktop'}>Data</h1>
                    <div className={'pa-pointer-image-wrapper'}>
                        <img alt='data' width={180} src={'pa-data.svg'}/>
                    </div>
                    <div className={'pa-bar'}/>
                    <h1 className={'people-analytics-pointer-header-mobile'}>Data</h1>
                    <div className={'pa-bar'}/>
                </div>
                <div className={'people-analytics-pointer'}>
                    <h1 className={'people-analytics-pointer-header-desktop'}>People</h1>
                    <div className={'pa-pointer-image-wrapper'}>
                        <img alt='people' width={180} src={'pa-people.svg'}/>
                    </div>
                    <div className={'pa-bar'}/>
                    <h1 className={'people-analytics-pointer-header-mobile'}>People</h1>
                    <div className={'pa-bar'}/>
                </div>
            </div>
            <div className={'people-analytics-sustainability'}>
                <div className={'people-analytics-sustainability-image-wrapper'}>
                    <img src={'pa-sustainability.svg'} alt={'DRIVE BUSINESS SUCCESS'}/>
                </div>
                <div className={'people-analytics-sustainability-text'}>
                    DRIVE BUSINESS SUCCESS & SUSTAINABILITY
                </div>
            </div>
        </div>
        <div className={'section-header'}>
            <h1>
                HR Analytics
            </h1>
        </div>
        <div className={'side-padded'}>
            <p className={'service-intro'}>
                We use predictive analytics to improve people impact in the business, value and retain talent. We use a
                scientific approach in the design of HR standards, models and solutions.
                <br/>
            </p>
        </div>
        <HrAnalyticsService header={'Hiring analytics'}
                            imageSrc={'hiring-analytics.svg'}
                            serviceItems={['Skills analytics', 'Cost per hire', 'Retention Rate', 'Reduce hiring risks', 'Impartial decision making']}/>

        <HrAnalyticsService header={'Optimisation analytics'}
                            imageSrc={'optimisation-analytics.svg'}
                            serviceItems={[' Predictive analytics', 'Attrition risk', 'Payroll leakage analytics', 'Optimum resource utilisation', 'Idle time & overtime analytics', 'Maximise business outcomes']}
                            reverse/>

        <HrAnalyticsService header={'Feedback analytics'}
                            imageSrc={'feedback-analytics.svg'}
                            serviceItems={['Workforce analytics', 'Appropriate skills for optimum performance', 'Performance drivers vs company goals']}
        />

        <Enquire services={["People Analytics"]}/>
    </section>
);
