import React from "react";
import "../index.css";

export default ({bg, text}) => (
    <div className="hero"
         style={{backgroundImage: `url('${bg}')`}}>
        {text && <div className="hero-contents">
            {text}
        </div>}
    </div>
);
