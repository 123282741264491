import React from "react";
import {Link} from "react-router-dom";

export default () => (

    <div className="tile">
        <div className={"tile-image-wrapper"}>
            <img
                src={"contact_300x400.jpg"}
                alt={"contact us"}/>
        </div>

        <div className="contact-us-tile-content">
            <Link className="button" to={"/contact-us"}>Contact Us</Link>
        </div>
    </div>
)
